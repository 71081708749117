<template>
  <div class="animated fadeIn">
    <b-card>
      <div class="card-body">
        <form>
          <h5>
            Contact details <b-spinner v-if="isLoading" small type="grow" />
          </h5>

          <hr />
          <b-row>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.firstname.id"
                :value="data.firstname"
                :label="controls.firstname.label"
                :readonly="false"
                :mode="mode"
                :required="true"
                @changed="updateField"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.lastname.id"
                :value="data.lastname"
                :label="controls.lastname.label"
                :readonly="false"
                :mode="mode"
                :required="true"
                @changed="updateField"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.email.id"
                :value="data.email"
                :label="controls.email.label"
                :readonly="false"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.phone.id"
                :value="data.phone"
                :label="controls.phone.label"
                :readonly="false"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
          </b-row>

          <h5>Select related account or create new</h5>
          <hr />

          <b-row>
            <b-col cols="6" class="d-flex align-items-center">
              <inline-select
                :width="30"
                :id="controls.account.id"
                :ref="controls.account.id"
                :value="data.account"
                :label="controls.account.label"
                :readonly="controls.account.readonly"
                :options="controls.account.options"
                :loading="controls.account.loading"
                :async="true"
                @async-search="onAccountSearch"
                :allow-empty="false"
                :multiple="true"
                :mode="mode"
                @changed="updateSelect"
                :required="!isNewAccount"
              />

              <b-button
                v-b-toggle.collapse-account
                variant="outline-dark"
                class="align-self-left mt-2 ml-2"
                @click="addAccount()"
              >
                <font-awesome-icon icon="plus" />
              </b-button>
            </b-col>
          </b-row>
          <b-collapse id="collapse-account" class="mt-3">
            <h5>New account details</h5>

            <hr />
            <b-row>
              <b-col lg="3" md="3">
                <inline-input
                  :id="controls.account_name.id"
                  :value="data.account_name"
                  :label="controls.account_name.label"
                  :readonly="false"
                  :mode="mode"
                  :required="isNewAccount"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="3">
                <inline-select
                  :id="controls.account_category.id"
                  :value="data.account_category"
                  :label="controls.account_category.label"
                  :readonly="controls.account_category.readonly"
                  :options="controls.account_category.options"
                  :allow-empty="false"
                  :mode="mode"
                  @changed="updateSelect"
                  :required="isNewAccount"
                />
              </b-col>
            </b-row>
          </b-collapse>

          <h5>Related documents</h5>
          <hr />
          <files-container
            ref="files"
            :module-id="$route.meta.module.id"
            :entity-id="id"
            @loaded="onFileContainerLoad"
          />
        </form>
        <hr />

        <form-submission-actions
          :mode="mode"
          :loading="{
            save: saveInProgress,
            saveAndView: saveAndViewInProgress
          }"
          @save="
            save('tabular').then(response =>
              response ? $router.push({ name: 'Contacts' }) : false
            )
          "
          @save-and-view="
            save('view').then(response =>
              response
                ? $router.push({
                    name: 'Contact submission',
                    params: { action: 'view', id: response }
                  })
                : false
            )
          "
          @edit="
            $router.push({
              name: 'Contact submission',
              params: { action: 'edit', id: id }
            })
          "
          @back="$router.push($store.getters['router/previousRoute'])"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import InlineInput from "@/components/InlineInput";
import InlineSelect from "@/components/InlineSelectMultiple";

import InlineEditor from "@ckeditor/ckeditor5-build-inline";

import controls from "./controls";

import FilesContainer from "@/components/FilesContainer";

import sharedEvents from "@/shared/sharedEvents.js";

import FormSubmissionActions from "@/components/FormSubmissionActions";

import { mapState, mapActions } from "vuex";

export default {
  name: "ContactSubmissionForm",
  components: {
    InlineInput,
    InlineSelect,
    FilesContainer,
    FormSubmissionActions
  },
  data: function() {
    return {
      id: undefined,
      editor: InlineEditor,
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      controls: controls,
      data: {
        id: "",
        firstname: "",
        lastname: "",
        phone: "",
        email: "",
        account_name: "",
        account_category: {
          id: "",
          label: ""
        },
        account: []
      },
      districts: [],
      isNewAccount: false
    };
  },
  computed: mapState({
    profile: state => state.profile
  }),
  created: function() {
    this.initialize();
  },
  mounted: function() {},
  methods: {
    ...mapActions("profile", ["fetchProfile"]),
    onFileContainerLoad(count) {
      if (count > 0) this.controls.tabs.files.title = `Files (${count})`;
    },
    initialize: async function() {
      this.mode = this.$constants.FORM_MODE.CREATE;

      this.id = await this.$api.get("entities/nextid");

      this.data.id = this.id;

      this.$api.get("dictionaries/accounts/categories").then(response => {
        this.controls.account_category.options = response.map(item => {
          return {
            id: item.id,
            label: item.name
          };
        });
      });
    },
    onAccountSearch(payload) {
      let self = this;

      self.controls.account.loading = true;

      this.$api
        .post("dictionaries/accounts", {
          norestrict: true,
          query: payload.query,
          starts_with: payload.startsWith
        })
        .then(response => {
          self.controls.account.loading = false;

          self.controls.account.options = response.map(u => ({
            id: u.id,
            label: u.name
          }));
        });
    },
    updateField(field, value) {
      let self = this;
      let params = {};
      params["id"] = this.id;
      params[field] = value;

      //update local data values - it can be used in Edit action
      self.data[field] = value;

      //if control in View mode then update database
      if (this.mode === this.$constants.FORMCONTROLMODE.VIEW) {
        this.isLoading = true;
        this.$api
          .put(`contacts/${this.data.id}`, params)
          .then(response => {
            this.isLoading = false;
            this.$form.makeToastInfo(`${field}: ${response.message}`);

            self.controls[field].changed = false;

            this.initialize();
          })
          .catch(response => {
            this.isLoading = false;
            console.log(response);

            this.$form.makeToastError(`${field}: ${response.message}`);
          });
      }
    },
    updateSelect(id, value) {
      this.updateField(id, value);
    },
    addAccount() {
      this.isNewAccount = !this.isNewAccount;
    },
    edit() {},

    save(_mode) {
      if (!this.$form.testForm(this)) {
        this.$form.makeToastError("Form contains errors");
        return Promise.resolve(false);
      }

      //let self = this;
      let method = "";

      let url = "contacts";
      if (this.mode === this.$constants.FORM_MODE.CREATE) {
        method = "post";
        url = "contacts/quick";
      }

      this.isLoading = true;
      this.saveInProgress = _mode === "tabular";
      this.saveAndViewInProgress = _mode === "view";

      return this.$api[method](url, this.data)
        .then(response => {
          this.isLoading = false;
          this.saveInProgress = false;
          this.saveAndViewInProgress = false;

          if (this.mode === this.$constants.FORM_MODE.CREATE) {
            sharedEvents.emit("newContactCreated");
          }

          this.$form.makeToastInfo(response.message);

          this.$router.currentRoute.params.id = response.id;

          return response.id;
        })
        .catch(error => {
          this.isLoading = false;
          this.saveInProgress = false;
          this.saveAndViewInProgress = false;

          this.$form.makeToastError(error.message);

          return Promise.resolve(false);
        });
    }
  },
  watch: {}
};
</script>

<style></style>

export default {
  tabs: {
    files: {
      title: "Files"
    }
  },
  buttons: {
    edit: {
      id: "button:details_edit"
    },
    save: {
      id: "button:details_save"
    }
  },
  firstname: {
    id: "input:firstname",
    label: "First Name",
    changed: false,
    readonly: false
  },
  lastname: {
    id: "input:lastname",
    label: "Last Name",
    changed: false,
    readonly: false
  },
  phone: {
    id: "input:phone",
    label: "Phone",
    changed: false,
    readonly: false
  },
  email: {
    id: "input:email",
    label: "Email",
    changed: false,
    readonly: false
  },
  account: {
    id: "select:account",
    label: "Account",
    options: [],
    changed: false,
    readonly: false,
    loading: false,
    required: true
  },
  account_name: {
    id: "input:account_name",
    label: "Account Name",
    changed: false,
    readonly: false
  },
  account_category: {
    id: "select:account_category",
    label: "Category",
    options: [],
    changed: false,
    readonly: false
  }
};

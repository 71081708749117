var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('div', {
    staticClass: "card-body"
  }, [_c('form', [_c('h5', [_vm._v(" Contact details "), _vm.isLoading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e()], 1), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.firstname.id,
      "value": _vm.data.firstname,
      "label": _vm.controls.firstname.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.lastname.id,
      "value": _vm.data.lastname,
      "label": _vm.controls.lastname.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.email.id,
      "value": _vm.data.email,
      "label": _vm.controls.email.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.phone.id,
      "value": _vm.data.phone,
      "label": _vm.controls.phone.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('h5', [_vm._v("Select related account or create new")]), _c('hr'), _c('b-row', [_c('b-col', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "cols": "6"
    }
  }, [_c('inline-select', {
    ref: _vm.controls.account.id,
    attrs: {
      "width": 30,
      "id": _vm.controls.account.id,
      "value": _vm.data.account,
      "label": _vm.controls.account.label,
      "readonly": _vm.controls.account.readonly,
      "options": _vm.controls.account.options,
      "loading": _vm.controls.account.loading,
      "async": true,
      "allow-empty": false,
      "multiple": true,
      "mode": _vm.mode,
      "required": !_vm.isNewAccount
    },
    on: {
      "async-search": _vm.onAccountSearch,
      "changed": _vm.updateSelect
    }
  }), _c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.collapse-account",
      modifiers: {
        "collapse-account": true
      }
    }],
    staticClass: "align-self-left mt-2 ml-2",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.addAccount();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "plus"
    }
  })], 1)], 1)], 1), _c('b-collapse', {
    staticClass: "mt-3",
    attrs: {
      "id": "collapse-account"
    }
  }, [_c('h5', [_vm._v("New account details")]), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.account_name.id,
      "value": _vm.data.account_name,
      "label": _vm.controls.account_name.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": _vm.isNewAccount
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.account_category.id,
      "value": _vm.data.account_category,
      "label": _vm.controls.account_category.label,
      "readonly": _vm.controls.account_category.readonly,
      "options": _vm.controls.account_category.options,
      "allow-empty": false,
      "mode": _vm.mode,
      "required": _vm.isNewAccount
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1)], 1)], 1), _c('h5', [_vm._v("Related documents")]), _c('hr'), _c('files-container', {
    ref: "files",
    attrs: {
      "module-id": _vm.$route.meta.module.id,
      "entity-id": _vm.id
    },
    on: {
      "loaded": _vm.onFileContainerLoad
    }
  })], 1), _c('hr'), _c('form-submission-actions', {
    attrs: {
      "mode": _vm.mode,
      "loading": {
        save: _vm.saveInProgress,
        saveAndView: _vm.saveAndViewInProgress
      }
    },
    on: {
      "save": function save($event) {
        _vm.save('tabular').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Contacts'
          }) : false;
        });
      },
      "save-and-view": function saveAndView($event) {
        _vm.save('view').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Contact submission',
            params: {
              action: 'view',
              id: response
            }
          }) : false;
        });
      },
      "edit": function edit($event) {
        return _vm.$router.push({
          name: 'Contact submission',
          params: {
            action: 'edit',
            id: _vm.id
          }
        });
      },
      "back": function back($event) {
        return _vm.$router.push(_vm.$store.getters['router/previousRoute']);
      }
    }
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }